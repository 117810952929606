import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import ReportDownloadButton from '@/components/contract/elements/ReportDownloadButton/ReportDownloadButton.vue';

export default {
	name: 'ContractReport',
	props: {},
	data: () => ({
		dateRange: '',
		filters: {
			q: null,
			page: 1,
			status: null,
			date_to: null,
			date_from: null,
			pagination: true,
			per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
		},
		expanded: [],
		singleExpand: true,
		isLoading: false,
		statusList: [
			{ value: 'PENDING', text: 'Pendiente' },
			{ value: 'DRAFT', text: 'Borrador' },
		],
		headers: [
			{ text: '', value: 'data-table-expand' },
			{
				text: 'Cliente',
				value: 'owner-name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'RUC',
				value: 'owner_document',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Contrato',
				value: 'name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Alcance',
				value: 'alcance',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Monto de contrato',
				value: 'ammount',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Inicio',
				value: 'service-start',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Final',
				value: 'service-end',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Observaciones',
				value: 'observations',
				sortable: false,
				class: 'primary--text',
			},

			{
				text: 'Cláusula de Confidencialidad',
				value: 'confidentiality-clause',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Cláusula de Cambio de Control',
				value: 'change-control-clause',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Resolución Unilateral',
				value: 'unilateral-resolution',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Reajuste de Tarifas',
				value: 'readjustment-rates',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Cambio en las Leyes',
				value: 'change-laws',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Límite de Penalidad',
				value: 'penalty-limit',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Límite de Responsabilidad',
				value: 'limit-liability',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Exclusión de daños indirectos y lucro cesante',
				value: 'exclusion-consequential-profits',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Formula polinómica',
				value: 'polynomial-formula',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'IPC',
				value: 'ipc',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Otros',
				value: 'others',
				align: 'right',
				sortable: false,
				class: 'primary--text',
			},
			{ text: '', value: 'data-table-expand' },
		],
		headersDocument: [
			{ text: '', value: 'data-table-expand' },
			{
				text: 'Cliente',
				value: 'c-name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'RUC',
				value: 'c-document',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Contrato',
				value: 'name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Alcance',
				value: 'alcance',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Monto de contrato',
				value: 'ammount',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Inicio',
				value: 'service-start',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Final',
				value: 'service-end',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Observaciones',
				value: 'observations',
				sortable: false,
				class: 'primary--text',
			},

			{
				text: 'Cláusula de Confidencialidad',
				value: 'confidentiality-clause',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Cláusula de Cambio de Control',
				value: 'change-control-clause',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Resolución Unilateral',
				value: 'unilateral-resolution',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Reajuste de Tarifas',
				value: 'readjustment-rates',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Cambio en las Leyes',
				value: 'change-laws',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Límite de Penalidad',
				value: 'penalty-limit',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Límite de Responsabilidad',
				value: 'limit-liability',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Exclusión de daños indirectos y lucro cesante',
				value: 'exclusion-consequential-profits',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Formula polinómica',
				value: 'polynomial-formula',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'IPC',
				value: 'ipc',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Comentarios',
				value: 'comment',
				sortable: false,
				class: 'primary--text',
			},
			{ text: '', value: 'data-table-expand' },
		],
		listReports: [],
	}),
	computed: {
		...mapState('report', ['reports', 'reports_pagination']),
		breadcrumbs: function () {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Legal',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'ContractHome',
					},
				},
				{
					text: 'Reporte',
					disabled: true,
					href: '/',
				},
			];
		},
	},
	created() {
		this.getReports();
	},
	methods: {
		...mapActions('report', ['listReport']),

		async getReports() {
			this.isLoading = true;
			await this.listReport(this.filters);
			this.isLoading = false;
			this.listData();
		},
		getContractItems(item, key) {
			let { contractForm } = item;
			contractForm = Array.isArray(contractForm) ? contractForm : [];
			const found = contractForm.find((e) => e.field.key == key);
			return found?.value || null;
		},
		cutText(input) {
			if (input && input.length > 30) {
				return input.substring(0, 30) + '...';
			}
			return input;
		},
		getLength(arr) {
			if (Array.isArray(arr)) {
				return arr.length;
			} else {
				return 0;
			}
		},

		listDocuments(list) {
			let newList = [];
			newList = list.map((report) => {
				let documents = [];
				let beforedocuments = Array.isArray(report.documents)
					? report.documents
					: [];
				beforedocuments.forEach((document) => {
					if (
						document.type == 'ADDENDUM' &&
						document?.contractDocumentForm?.length > 0
					) {
						let obj = {};
						document.contractDocumentForm.forEach((element) => {
							obj = {
								...obj,
								[element?.field?.key]: this.cutText(element.value),
							};
						});
						obj = { ...obj, comment: document.comment };
						documents.push(obj);
					}
				});
				return { ...report, documents };
			});
			return newList;
		},

		listData() {
			const headers = [
				{ key: 'id', ubication: 'base' },
				{ key: 'name', ubication: 'owner' },
				{ key: 'document', ubication: 'owner' },
				{ key: 'name', ubication: 'base' },
				{ key: 'alcance', ubication: 'contractForm' },
				{ key: 'ammount', ubication: 'contractForm' },
				{ key: 'service-start', ubication: 'contractForm' },
				{ key: 'service-end', ubication: 'contractForm' },
				{ key: 'observations', ubication: 'contractForm' },
				{ key: 'confidentiality-clause', ubication: 'contractForm' },
				{ key: 'change-control-clause', ubication: 'contractForm' },
				{ key: 'unilateral-resolution', ubication: 'contractForm' },
				{ key: 'readjustment-rates', ubication: 'contractForm' },
				{ key: 'change-laws', ubication: 'contractForm' },
				{ key: 'penalty-limit', ubication: 'contractForm' },
				{ key: 'limit-liability', ubication: 'contractForm' },
				{ key: 'exclusion-consequential-profits', ubication: 'contractForm' },
				{ key: 'polynomial-formula', ubication: 'contractForm' },
				{ key: 'ipc', ubication: 'contractForm' },
				{ key: 'others', ubication: 'base' },
				{ key: 'documents', ubication: 'other' },
			];
			let list = this.reports.map((report) => {
				let entries = {};
				headers.forEach((element) => {
					let value = null;
					let key = element.key;
					if (element.ubication === 'base') {
						value = this.cutText(report[key]);
					} else if (element.ubication === 'owner') {
						value = this.cutText(report?.owner?.[key]);
						key = 'owner_' + key;
					} else if (element.ubication === 'contractForm') {
						value = this.cutText(this.getContractItems(report, key));
					} else if (element.ubication === 'other') {
						value = report[key];
					}
					entries = { ...entries, [key]: value };
				});
				return entries;
			});
			list = this.listDocuments(list);
			this.listReports = list;
		},

		listenFilters() {
			this.getReports();
		},

		listenDatePicker(val) {
			moment.locale('es');
			this.filters.date_from = val[0] ? moment(val[0]).format('YYYY-MM-DD') : null;
			this.filters.date_to = val[1] ? moment(val[1]).format('YYYY-MM-DD') : null;
			this.listenFilters();
		},
	},
	destroyed() {},
	components: {
		ReportDownloadButton,
	},
};
