import { $EventBus } from '@/main';
import { getReportFile } from '@/store/modules/report/service/report.service';
export default {
	name: 'ReportDownloadButton',
	props: {},
	data: () => ({
		loading: false,
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		generateLinkDownload(responseApi) {
			const { data, headers } = responseApi;
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			const contentDisposition = headers['content-disposition'];
			let filename = 'Reporte.xlsx';

			if (contentDisposition) {
				const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
				if (filenameMatch && filenameMatch[1]) {
					filename = filenameMatch[1];
				}
			}

			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
			window.URL.revokeObjectURL(url);
		},

		async loadReportFile() {
			try {
				this.loading = true;
				const responseApi = await getReportFile();
				this.generateLinkDownload(responseApi);
			} catch (error) {
				let message = error?.toString();
				const errorBlob = error?.response?.data;
				if (errorBlob instanceof Blob) {
					const errorText = await errorBlob?.text();
					const errorJson = JSON.parse(errorText);
					message = errorJson?.message;
				}
				$EventBus.$emit('showSnack', 'error', message);
			} finally {
				this.loading = false;
			}
		},
	},
	beforeDestroy() {},
	components: {},
};
